@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background: #0a0a16;
}

html {
  @apply scroll-smooth;
}

@keyframes dash {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 10;
    }
  }
  
  .animate-dash {
    animation: dash 1s linear infinite;
  }

  @keyframes pulse-border {
    0% {
      border-width: 2px;
      border-color: rgba(255, 0, 0, 0.5);
    }
    50% {
      border-width: 4px;
      border-color: rgba(255, 0, 0, 1);
    }
    100% {
      border-width: 2px;
      border-color: rgba(255, 0, 0, 0.5);
    }
  }
  
  .pulsate-border {
    animation: pulse-border 1.5s infinite;
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(60, 93, 226, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
  
  .ripple-effect {
    position: absolute;
  }
  
  .ripple-effect::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: ripple 1.5s infinite;
    z-index: -1;
  }
  

  .custom-shape-divider-bottom-1724621134 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1724621134 svg {
    position: relative;
    display: block;
    width: calc(214% + 1.3px);
    height: 116px;
}

.custom-shape-divider-bottom-1724621134 .shape-fill {
    fill: #ffffff1e;
}
